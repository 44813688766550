import React, { useContext, useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { PreviousView } from "./PreviousView";
import { UserContext } from "../../contexts/UserContext";


export const PreviousContainer = () => {
    const { user, setUser } = useContext(UserContext);

    const [selectedAuction, setSelectedAuction] = useState(null);

    const handleAuctionChange = (auction) => {
        setSelectedAuction(auction);
    };

    useEffect(() => {
        const fetchItems = async () => {
            try {
                const response = await axios.get('/api/items/getItemsBySellerIDAndAuction', {
                    params: {
                        userId: user.id,
                        auctionId: selectedAuction ? selectedAuction.id : null
                    },
                    headers: authHeader(),
                });
                setItems(response.data);
            } catch (err) {
                console.error('Error fetching items:', err);
            }
        };

        if (user.id && selectedAuction) {
            fetchItems();
        }
    }, [selectedAuction]);

    return (
        <div>
            <PreviousView 
                selectedAuction={selectedAuction}
                handleAuctionChange={handleAuctionChange}
            />
        </div>
    );
};