import { Button, Modal, Typography } from '@mui/material';
import React from 'react';

const ProfileCompletionPopup = (props) => {
    const setShowCompleteProfile = props.setShowCompleteProfile;
    return (
        <Modal open={true}>
            <div
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'white',
                    padding: '20px',
                    minWidth: '300px',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Typography variant="h6">Complete Your Profile</Typography>
                <Typography variant="body1">
                    Please complete your profile & account information to continue.
                </Typography>
                <Button
                    style={{ marginTop: '20px', alignSelf: 'flex-end' }}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        setShowCompleteProfile(false)
                    }}
                >
                    OK
                </Button>
            </div>
        </Modal>
    );
};

export default ProfileCompletionPopup;
