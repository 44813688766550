import React, { useState } from 'react';
import UpcomingAuctionDropdown from '../../components/AuctionDropdown/UpcomingAuctionDropdown';
import ItemsTable from '../../components/ItemsTable/ItemsTable';
import { Button, Grid, Typography } from '@mui/material';
import AddItemModal from '../../components/Modals/AddItemModal';
import DeleteItemModal from '../../components/Modals/DeleteItemModal';
import EditItemModal from '../../components/Modals/EditItemModal';

export const UpcomingView = (props) => {
    const [modals, setModals] = useState({
        addItemModal: false,
        deleteItemModal: false,
        bulkEditModal: false,
        editItemModal: false
    });
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedItemId, setSelectedItemId] = useState();

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400, 
        bgcolor: 'background.paper', 
        boxShadow: 24, 
        p: 4,
    };

    const selectedAuction = props.selectedAuction;
    const handleAuctionChange = props.handleAuctionChange;
    const items = props.items;
    const setItems = props.setItems;

    const handleModalOpen = (modalName) => {
        setModals(prevModals => ({ ...prevModals, [modalName]: true }));
    };

    const handleModalClose = (modalName) => {
        setModals(prevModals => ({ ...prevModals, [modalName]: false }));
    };

    const openEditModal = (itemId) => {
        setSelectedItemId(itemId);
        setModals(prevModals => ({
            ...prevModals,
            editItemModal: true
        }));
    };
    
    return (
        <div>
            <Grid container direction='column'>
                <Grid item>
                    <UpcomingAuctionDropdown
                        selectedAuction={selectedAuction}
                        onAuctionChange={handleAuctionChange}
                    />
                </Grid>
                {selectedAuction && (
                    <>
                        <Grid item>
                            <Typography variant="body2" style={{ marginTop: '20px' }}>
                                Date: {new Date(selectedAuction.startDate).toLocaleDateString()} -
                                {new Date(selectedAuction.endDate).toLocaleDateString()}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ margin: "15px 5px 15px 0px" }}
                                onClick={() => handleModalOpen('addItemModal')}
                            >New Lot
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ margin: "15px 5px" }}
                                onClick={() => handleModalOpen('deleteItemModal')}
                            >Delete</Button>
                        </Grid>
                        <Grid item>

                        </Grid>
                        <Grid item>
                            <ItemsTable
                                items={items}
                                onEdit={openEditModal}
                                selectedItems={selectedItems}
                                setSelectedItems={setSelectedItems}
                            />
                        </Grid>
                    </>
                )}
            </Grid>
            <AddItemModal
                open={modals.addItemModal}
                onClose={() => handleModalClose('addItemModal')}
                auction={selectedAuction}
                items={items}
                setItems={setItems}
            />
            <DeleteItemModal
                open={modals.deleteItemModal}
                onClose={() => handleModalClose('deleteItemModal')}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                items={items}
                setItems={setItems}
                auction={selectedAuction}
            />
            <EditItemModal
                open={modals.editItemModal}
                onClose={() => setModals(prevModals => ({ ...prevModals, editItemModal: false }))}
                itemId={selectedItemId}
                auction={selectedAuction}
            />
        </div>
    )
}