import React, { useRef, useEffect, useState } from "react";
import { Button, FormHelperText, Grid, TextField, Typography, useTheme } from "@mui/material";
import Autocomplete from "@mui/lab/Autocomplete";
import axios from "axios";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
})

const Signup = () => {
    const googleAPIKey = process.env.TAM_GOOGLE_API_KEY;

    const classes = useStyles()

    const [userAddress, setUserAddress] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        postalCode: '',
        country: ''
    });
    const [isFormValid, setIsFormValid] = useState(false);
    const [options, setOptions] = useState([]);
    const [registrationStatus, setRegistrationStatus] = useState(false);
    const addressInputRef = useRef(null);
    const theme = useTheme();

    useEffect(() => {
        setIsFormValid(
            userAddress.firstName.trim() !== '' &&
            userAddress.lastName.trim() !== '' &&
            userAddress.email.trim() !== '' &&
            userAddress.phoneNumber.trim() !== '' &&
            userAddress.addressLine1.trim() !== '' &&
            userAddress.city.trim() !== '' &&
            userAddress.state.trim() !== '' &&
            userAddress.postalCode.trim() !== '' &&
            userAddress.country.trim() !== ''
        );

        if (!window.google) {
            console.error("Google Maps API is not loaded");
            return;
        }

        const autocomplete = new window.google.maps.places.Autocomplete(addressInputRef.current, {
            types: ['address']
        });

        autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();

            if (place.address_components) {
                const streetNumber = getPlaceComponent(place.address_components, 'street_number');
                const streetName = getPlaceComponent(place.address_components, 'route');
                const city = getPlaceComponent(place.address_components, 'locality');
                const state = getPlaceComponent(place.address_components, 'administrative_area_level_1');
                const postalCode = getPlaceComponent(place.address_components, 'postal_code');
                const country = getPlaceComponent(place.address_components, 'country');

                setUserAddress(prevState => ({
                    ...prevState,
                    addressLine1: `${streetNumber} ${streetName}`,
                    city,
                    state,
                    postalCode,
                    country
                }));
            }
        });

        return () => {
            window.google.maps.event.clearInstanceListeners(autocomplete);
        };

    }, [userAddress]);

    const getPlaceComponent = (components, type) => {
        const component = components.find(comp => comp.types.includes(type));
        return component ? component.long_name : '';
    };

    const handleChange = (event) => {
        setUserAddress({
            ...userAddress,
            [event.target.name]: event.target.value
        });
    };

    const handleAddressChange = (event) => {
        const { value } = event.target;
        setUserAddress((userAddress) => ({
            ...userAddress,
            addressLine1: value,
        }));
        // fetchAddressSuggestions(value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log(userAddress)
        try {
            const response = await axios.post('http://localhost:3000/api/register', userAddress);
            console.log(response.data);
            setRegistrationStatus(true);
        } catch (error) {
            console.error(error);
        }
    };

    const handlePhoneNumberChange = (event) => {
        const { value } = event.target;
        setUserAddress((userAddress) => ({
            ...userAddress,
            phoneNumber: value
        }));
    };

    return (
        <div>
            {registrationStatus ? (
                <Typography variant="h6">
                    Thank you for your interest! Your request is pending and we will notify you via email once approved.
                </Typography>
            ) : (
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="First Name"
                                fullWidth
                                name="firstName"
                                variant="outlined"
                                color="primary"
                                value={userAddress.firstName}
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Last Name"
                                fullWidth
                                name="lastName"
                                variant="outlined"
                                color="primary"
                                value={userAddress.lastName}
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Email"
                                fullWidth
                                name="email"
                                type="email"
                                variant="outlined"
                                color="primary"
                                value={userAddress.email}
                                onChange={handleChange}
                                required
                            />
                            <FormHelperText style={{ color: theme.palette.error.main }}>
                                * Note: This email address is published in your listings
                            </FormHelperText>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Phone Number"
                                fullWidth
                                name="phoneNumber"
                                variant="outlined"
                                color="primary"
                                value={userAddress.phoneNumber}
                                onChange={handlePhoneNumberChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                label="Address"
                                id="address-input"
                                fullWidth
                                variant="outlined"
                                color="primary"
                                value={userAddress.addressLine1}
                                onChange={handleAddressChange}
                                InputProps={{
                                    inputRef: addressInputRef
                                }}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                label="Address Line 2"
                                fullWidth
                                name="addressLine2"
                                variant="outlined"
                                color="primary"
                                value={userAddress.addressLine2}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                label="City"
                                fullWidth
                                name="city"
                                variant="outlined"
                                color="primary"
                                value={userAddress.city}
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="State/Province"
                                fullWidth
                                name="state"
                                variant="outlined"
                                color="primary"
                                value={userAddress.state}
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Postal Code"
                                fullWidth
                                name="postalCode"
                                variant="outlined"
                                color="primary"
                                value={userAddress.postalCode}
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Country"
                                fullWidth
                                name="country"
                                variant="outlined"
                                color="primary"
                                value={userAddress.country}
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={!isFormValid}
                            >Signup</Button>
                        </Grid>
                    </Grid>
                </form>
            )}
        </div>
    );
};

export default Signup;