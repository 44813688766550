import {
    Alert,
    Button,
    Grid,
    LinearProgress,
    List,
    ListItem,
    ListItemText, Paper, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow,
    TextField,
    Typography
} from "@mui/material";
import React, {useState} from "react";
import axios from "axios";
import authHeader from "../../services/auth-header";

export const SyncTotals = ({selectedSyncAuction}) => {
    const [loading, setLoading] = useState(false);
    const [invoices, setInvoices] = useState([]);

    const generateInvoices = async () => {
        try {
            setLoading(true)
            const response = await axios.get(`/api/auctions/${selectedSyncAuction.id}/generateInvoices`, {
                headers: authHeader(),
            });
            console.log(response.data.invoices)
            setInvoices(response.data.invoices);
            setLoading(false)
        } catch (error) {
            console.error("Error generating invoices:", error.response ? error.response.data : error);
        }
    }

    return (
        <Grid container xs={12} spacing={2} alignItems="center">
            {
                loading ?
                    (
                        <Grid item xs={12}>
                            {console.log(invoices)}
                            <LinearProgress/>
                        </Grid>
                    ) : (
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={generateInvoices}
                            >Generate Invoices</Button>
                        </Grid>
                    )
            }
            {invoices && invoices.length > 0 ? (
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Invoice ID</TableCell>
                                    <TableCell>Customer Name</TableCell>
                                    <TableCell>Seller Name</TableCell>
                                    <TableCell>Total Charges</TableCell>
                                    <TableCell>Item Count</TableCell>
                                    <TableCell>Sales Tax</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {invoices.map((invoice) => (
                                    <TableRow key={invoice.invoice_id}>
                                        <TableCell>{invoice.invoice_id}</TableCell>
                                        <TableCell>{invoice.customer_name}</TableCell>
                                        <TableCell>{invoice.seller_name}</TableCell>
                                        <TableCell>{invoice.item_charges?.toFixed(2) || "0.00"}</TableCell>
                                        <TableCell>{invoice.item_count}</TableCell>
                                        <TableCell>{invoice.sales_tax?.toFixed(2) || "0.00"}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            ) : (
                <Typography variant="body1">No invoices available</Typography>
            )}
        </Grid>
    );
};