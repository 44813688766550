import React from "react";
import TopMenuView from "./TopMenuView";

// Container Component is for entry 
// - fetch data & pass props to view 
export function TopMenu(props) {
    return (
        <div>
            <TopMenuView {...props}/>
        </div>
    );
}