import React, { useContext, useEffect, useState } from "react"; 
import { Sidenav } from "../../components/Sidenav/Sidenav";
import { TopMenu } from "../../components/TopMenu/TopMenu";
import AdminSidenav from "../../components/AdminSideNav";
import { UserContext } from "../../contexts/UserContext";


export const AdminDashboard = () => {
    const {user, setUser} = useContext(UserContext);

    // useEffect(() => {
    //     setIsLoading(true);
    //     getAllStations()
    //         .then((res) => {
    //             console.log("Dashboard > getAllStations > res=", res);
    //             setStations(res);
    //             setIsLoading(false);
    //         })
    //         .catch((err) => {
    //             console.log("axios err=", err);
    //             setStations([]);
    //             setIsLoading(false);
    //         });

    //     return () => {
    //         console.log("axios cleanup.");
    //     }
    // }, []);

    return (
        <div>
            Hello Admin Dashboard - {user.firstName}
        </div>
    );
};