import React, {useState, useCallback} from 'react';
import {Alert} from '@mui/material';
import {IconButton, Typography, TextField, Button, useTheme, Grid, Checkbox} from "@mui/material"
import {makeStyles} from '@mui/styles';
import PreviousAuctionDropdown from "../../components/AuctionDropdown/PreviousAuctionDropdown";

const useStyles = makeStyles((theme) => ({}));

export const PreviousView = (props) => {
    const selectedAuction = props.selectedAuction;
    const handleAuctionChange = props.handleAuctionChange;

    return (
        <div>
            <Grid container direction='column'>
                <Grid item>
                    <PreviousAuctionDropdown
                        selectedAuction={selectedAuction}
                        onAuctionChange={handleAuctionChange}
                    />
                </Grid>
                {selectedAuction && (
                    <>
                        <Grid item>
                            <Typography variant="body2" style={{marginTop: '20px'}}>
                                Date: {new Date(selectedAuction.startDate).toLocaleDateString()} -
                                {new Date(selectedAuction.endDate).toLocaleDateString()}
                            </Typography>
                        </Grid>
                    </>
                )}
            </Grid>
        </div>
    )
}