import { createContext, useEffect, useState } from "react";
import jwtDecode from 'jwt-decode';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const storedToken = localStorage.getItem('token');

        if (storedToken) {
            const decodedToken = jwtDecode(storedToken);
            const currentTime = Date.now() / 1000; 
            if (decodedToken.exp < currentTime) {
                console.log("Token expired");
                localStorage.removeItem('token');
                setUser(null); 
            } else {
                setUser(JSON.parse(localStorage.getItem('user'))); 
            }
        }
    }, []);

    const value = {
        user,
        setUser,
        isAuthenticated: !!user
    };

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
