import React from 'react';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { makeStyles } from '@mui/styles';
import { Card, CardContent, CardHeader } from '@mui/material';

const useStyles = makeStyles(theme => ({
    card: {
        height: `100%`
    },
    badgeError: {
        color: theme.palette.error.main,
        backgroundColor: theme.palette.error.main
    }
}));

const CardView = (props) => {
    const content = props.content;
    const classes = useStyles()

    return (
        <Card elevation={1} className={classes.card}>
            <CardHeader
                action={(content.action) ?
                    <IconButton onClick={() => { console.log("Icon clicked")}}>
                        <Badge classes={{ badge: classes.badgeError}} variant="dot" >
                            <NotificationsIcon/>
                        </Badge>
                    </IconButton> : <></>
                }
                title={content.title}
                subheader={content.subheader}
            />
            <CardContent className={classes.card}>
                {content.body}
            </CardContent>
        </Card>
    )
}

export default CardView;