import { Button, Grid, LinearProgress, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import UpcomingAuctionDropdown from "../../components/AuctionDropdown/UpcomingAuctionDropdown";
import AllAuctionDropdown from "../../components/AuctionDropdown/AllAuctionDropdown";
import axios from "axios";
import authHeader from "../../services/auth-header";

export const SyncAuctionID = ({setSelectedSyncAuction}) => {
    const [auctionId, setAuctionID] = useState('');
    const [selectedAuction, setSelectedAuction] = useState(null);
    const [loading, setLoading] = useState(false);
    const [updatedAuctionInfo, setUpdatedAuctionInfo] = useState();

    useEffect(() => {
        if(selectedAuction) {
            setSelectedSyncAuction(selectedAuction);
            if(selectedAuction.auctionMobilityId){
                setUpdatedAuctionInfo(selectedAuction)
                setAuctionID(selectedAuction.auctionMobilityId)
            }
        }
    }, [selectedAuction]);

    const handleAuctionChange = (auction) => {
        setSelectedAuction(auction);
        setSelectedSyncAuction(auction);
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const data = {
                externalAuctionId: auctionId,
            };

            const response = await axios.put(`/api/auctions/${selectedAuction.id}/updateAuction`, data, {
                headers: authHeader(),
            });
            setLoading(false)
            setUpdatedAuctionInfo(response.data);
        } catch (error) {
            console.error("Error updating auction:", error.response ? error.response.data : error);
        }
    };

    return (
        <Grid container xs={12} spacing={2} alignItems="center">
            <Grid item xs={7}>
                <AllAuctionDropdown
                    selectedAuction={selectedAuction}
                    onAuctionChange={handleAuctionChange}
                />
            </Grid>
            <Grid item>
                <TextField
                    label="Enter Auction ID"
                    variant="outlined"
                    value={auctionId}
                    onChange={(e) => setAuctionID(e.target.value)}
                />
            </Grid>
            <Grid item>
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                    Submit
                </Button>
            </Grid>
            {
                loading &&
                (
                    <Grid item xs={12}>
                        <LinearProgress />
                    </Grid>
                )
            }
            {
                updatedAuctionInfo &&
                (
                    <Grid item xs={12}>
                        <Typography>Lot Count: {updatedAuctionInfo.lotCount}</Typography>
                        <Typography>Sold Lot Count: {updatedAuctionInfo.soldLotCount}</Typography>
                        <Typography>Hammer Price: ${updatedAuctionInfo.totalHammerPrice}</Typography>
                    </Grid>
                )
            }
        </Grid>
    );
};