import DashboardIcon from '@mui/icons-material/Dashboard';
import GavelIcon from '@mui/icons-material/Gavel';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';

const menuItems = [
    {
        text: "Dashboard",
        icon: <DashboardIcon color="primary" />,
        path: "/"
    }, 
    {
        text: "Upcoming Auctions",
        icon: <GavelIcon color="primary" />,
        path: "/upcoming"
    }, 
    {
        text: "Previous Auctions",
        icon: <ReceiptIcon color="primary" />,
        path: "/previous"
    }, 
    {
        text: "Settings",
        icon: <SettingsApplicationsIcon color="primary" />,
        path: "/settings"
    }
]

export default menuItems;