import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { ApproveSellersView } from "./ApproveSellersView";
import axios from "axios";


export const ApproveSellersContainer = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [unapprovedSellers, setUnapprovedSellers] = useState([]);
    const [infoAlert, setInfoAlert] = useState();

    const callUnapprovedSellers = () => {
        const token = localStorage.getItem('token');
        axios.get("/api/users/unapprovedSellers", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                setUnapprovedSellers(res.data);
                setIsLoading(false);
            }).catch((err) => {
                console.log("axios err=", err);
                setUnapprovedSellers([]);
                setIsLoading(false);
            });
    }

    useEffect(() => {
        setIsLoading(true);
        callUnapprovedSellers();
    }, []);


    const handleUserApproval = (userId, fullName) => {
        const token = localStorage.getItem('token');
        axios.put("/api/users/accept", { userId }, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                console.log('User accepted:', response.data);
                setInfoAlert(fullName + " was accepted");
                callUnapprovedSellers();
            })
            .catch((error) => {
                console.error('Error accepting user:', error);
            });
    }

    const handleUserRejection = (userId, fullName) => {
        const token = localStorage.getItem('token');
        axios.put("/api/users/reject", { userId }, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                console.log('User rejected:', response.data);
                setInfoAlert(fullName + " was rejected");
                callUnapprovedSellers();
            })
            .catch((error) => {
                console.error('Error rejecting user:', error);
            });
    }

    return (
        <div>
            <ApproveSellersView
                unapprovedSellers={unapprovedSellers}
                infoAlert={infoAlert}
                handleUserApproval={handleUserApproval}
                handleUserRejection={handleUserRejection}
            />
        </div>
    );
};