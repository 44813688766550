import React, { useContext, useEffect, useState } from "react";
import { Button, Grid, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { SyncAuctionID } from "./SyncAuctionID";
import { SyncContacts } from "./SyncContacts";
import { SyncTotals } from "./SyncTotals";

export const PostAuctionSyncView = () => {
    const [selectedSyncAuction, setSelectedSyncAuction] = useState([]);

    function getStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:
                return <SyncAuctionID
                    setSelectedSyncAuction={setSelectedSyncAuction}
                />;
            case 1:
                return <SyncContacts
                    selectedSyncAuction={selectedSyncAuction}
                />;
            case 2:
                return <SyncTotals
                    selectedSyncAuction={selectedSyncAuction}
                />;
            case 3:
                return 'Step 4 Content: Review and Submit';
            default:
                return 'Unknown Step';
        }
    }

    const [activeStep, setActiveStep] = useState(0);
    const steps = ['Sync Auction ID', 'Sync Contacts', 'Sync Lots', 'Generate Invoices'];

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <Grid container direction="column" spacing={4} style={{ height: '100%' }}>
            <Grid item>
                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Grid>
            <Grid item>
                <Typography>{getStepContent(activeStep)}</Typography>
            </Grid>
            <Grid item container spacing={2} justifyContent="flex-end">
                <Grid item>
                    <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                    >
                        Back
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                    >
                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
}