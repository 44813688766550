import React, { useState, useContext, Component, useEffect } from "react";
import { Button, FormControlLabel, Grid, Link, TextField, Typography, useTheme } from "@mui/material";
import { Alert } from "@mui/material";
import { Checkbox } from "@mui/material";
import axios from "axios";
import jwtDecode from 'jwt-decode';
import { UserContext } from "../../contexts/UserContext";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    field: {
        marginTop: 20,
        marginBottom: 20,
        display: "block"
    }
})

const CreatePassword = (props) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const classes = useStyles();
    const {user, setUser} = useContext(UserContext);
    const id = props.id;

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState();
    const [agreedToTerms, handleAgreeToTerms] = useState(false);

    useEffect(() => {
        axios.get('/api/sellers/getExistingSeller/'+id)
        .then((response) => {
            if(response.data !== 'Seller does not exist'){
                navigate("/login");
            }
        }).catch((error) => {
            navigate("/login");
        })
    }, []);

    const handleLogin = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            setMessage("Passwords do not match");
            return;
        }

        if (!agreedToTerms) {
            setMessage("You must agree to the Terms and Conditions")
            return;
        }

        try {
            await axios.post(`/api/sellers/createSellerPassword`, {
                id: id,
                email: email,
                password: password
            }).then((response) => {
                let curUser = response.data;
                setUser(response.data);
                localStorage.setItem('user', JSON.stringify(curUser));
                navigate(curUser.isAdmin ? "/admin" : "/dashboard");
            })

        } catch (error) {
            if (error.response) {
                if (error.response.statusText.includes('User not approved') || 
                error.response.statusText.includes('Not Found')) {
                    setMessage('Your registration is still pending, email info@theanglingmarketplace.com for more information');
                }
                if (error.response.statusText.includes('Seller already exists')) {
                    navigate("/login");
                }
            }
            console.error(error);
        }
    }

    return (
        <>
            <Grid container>
                <Grid item>
                    <div className="form-group">
                        {
                            message &&
                            <Alert severity="error">
                                {message}
                            </Alert>
                        }
                    </div>
                    <div className="form-group">
                        <TextField
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className={classes.field}
                            label="Email"
                            variant="outlined"
                            color="primary"
                            fullWidth
                        />
                    </div>

                    <div className="form-group">
                        <TextField
                            type="password"
                            name="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className={classes.field}
                            label="Password"
                            variant="outlined"
                            color="primary"
                            fullWidth
                        />
                    </div>
                    <div className="form-group">
                        <TextField
                            type="password"
                            name="confirmpassword"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            className={classes.field}
                            label="Confirm Password"
                            variant="outlined"
                            color="primary"
                            fullWidth
                        />
                    </div>

                    <FormControlLabel
                        control={<Checkbox
                            onChange={(e) => handleAgreeToTerms(e.target.checked)}
                            style={{
                                color: theme.palette.primary.main,
                            }}
                        />}
                        label={
                            <>
                                <Typography>I have read, understand and agree to the&nbsp;
                                    <Link target="_blank" href="http://subadmin.theanglingmarketplace.com/terms-and-conditions.pdf">
                                        Terms and Conditions of The Angling Marketplace
                                    </Link>
                                </Typography>
                            </>}
                    />

                    <div className="form-group">
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ margin: "15px 0px" }}
                            onClick={(e) => handleLogin(e)}
                        >Login</Button>
                    </div>
                </Grid>
            </Grid>
        </>
    );
};

export default CreatePassword;