import React from "react";
import SidenavView from "./SidenavView";

// Container Component is for entry 
// - fetch data & pass props to view 
export function Sidenav(props) {
    return (
        <div>
            <SidenavView {...props}/>
        </div>
    );
}