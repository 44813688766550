import React, { useState, useEffect } from 'react';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import AuctionService from '../../services/auction.service';

const AllAuctionDropdown = ({ selectedAuction, onAuctionChange }) => {
    const [auctions, setAuctions] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        AuctionService.getAllAuctions()
            .then(data => {
                setAuctions(data);
                if (!selectedAuction) {
                    const currentUpcomingAuction = getCurrentUpcomingAuction(data);
                    onAuctionChange(currentUpcomingAuction);
                }
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching auctions:', error);
                setLoading(false);
            });
    }, [selectedAuction, onAuctionChange]);

    const getCurrentUpcomingAuction = (auctions) => {
        const now = new Date();
        return auctions.find(auction => new Date(auction.endDate) >= now);
    };

    return (
        <Autocomplete
            options={auctions}
            getOptionLabel={(option) => option.auctionName}
            value={selectedAuction}
            onChange={(event, newValue) => {
                onAuctionChange(newValue);
            }}
            loading={loading}
            renderInput={(params) => (
                <TextField 
                    {...params}
                    label="Select an Auction"
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
};

export default AllAuctionDropdown;
