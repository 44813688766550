import React, { useState, useCallback } from 'react';
import { Alert, ToggleButton } from '@mui/material';
import { IconButton, Typography, TextField, Button, useTheme, Grid, Checkbox, Modal } from "@mui/material"
import { Autocomplete, Box } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';

import Card from '../../components/Card';
import Table from '../../components/Table';
import Collapse from '@mui/material/Collapse';
import { useNavigate } from 'react-router';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(2),
    },
    button: {
        marginLeft: theme.spacing(2),
    },
}));

export const ApproveSellersView = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const unapprovedSellers = props.unapprovedSellers;
    const infoAlert = props.infoAlert;
    const handleUserApproval = props.handleUserApproval;
    const handleUserRejection = props.handleUserRejection;
    const [displayAcceptRejectModal, setDisplayAcceptRejectModal] = useState(false);
    const [areYouSure, setAreYouSure] = useState();

    const approveRejectButtons = (userId, fullName) => (
        <Grid container spacing={1}>
            <Grid item>
                <ToggleButton
                    value="approve"
                    size='small'
                    onChange={() => {
                        setAreYouSure({ userId, fullName, "decision": "accept" });
                        setDisplayAcceptRejectModal(true);
                    }}
                    sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: 'white',
                        '&:hover': {
                            backgroundColor: theme.palette.primary.hover,
                        },
                    }}
                >
                    <CheckIcon />
                </ToggleButton>
            </Grid>
            <Grid item>
                <ToggleButton
                    value="reject"
                    size='small'
                    onChange={() => {
                        setAreYouSure({ userId, fullName, "decision": "reject" });
                        setDisplayAcceptRejectModal(true);
                    }}
                    sx={{
                        backgroundColor: theme.palette.error.main,
                        color: 'white',
                        '&:hover': {
                            backgroundColor: theme.palette.error.hover,
                        },
                    }}
                >
                    <ClearIcon />
                </ToggleButton>
            </Grid>
        </Grid>
    )

    const handleAreYouSure = (areYouSure) => {
        areYouSure.decision === 'accept' ?
            handleUserApproval(areYouSure.userId, areYouSure.fullName) :
            handleUserRejection(areYouSure.userId, areYouSure.fullName)

        setDisplayAcceptRejectModal(false)
    }

    return (
        <div>
            <Card
                content={{
                    title: "Pending Sellers",
                    body: <>
                        {infoAlert &&
                            <>
                                <Alert severity='info'>
                                    {infoAlert}
                                </Alert>
                                <br />
                            </>
                        }
                        {unapprovedSellers ? (
                            <Table
                                content={{
                                    pagenation: true,
                                    labels: [
                                        { title: "ID", align: "left" },
                                        { title: "User", align: "left" },
                                        { title: "Phone", align: "left" },
                                        { title: "Email", align: "left" },
                                        { title: "Status", align: "left" },
                                        { title: "Approve/Reject", align: "left" }],
                                    body:
                                        unapprovedSellers.map((unapproved) => ({
                                            name: unapproved.id,
                                            cells: [
                                                unapproved.id,
                                                unapproved.firstName + " " + unapproved.lastName,
                                                unapproved.phoneNumber,
                                                unapproved.email,
                                                unapproved.status,
                                                approveRejectButtons(unapproved.id,
                                                    unapproved.firstName + " " + unapproved.lastName)
                                            ]
                                        }))
                                }} />
                        ) : <></>}
                    </>
                }}
            />
            {displayAcceptRejectModal &&
                <Modal
                    open={displayAcceptRejectModal}
                    onClose={() => setDisplayAcceptRejectModal(false)}
                    className={classes.modal}
                >
                    <div className={classes.paper}>
                        <Typography variant="h6">
                            Are you sure you want to {areYouSure.decision} {areYouSure.fullName}?
                        </Typography>
                        <Box className={classes.buttonContainer}>
                            <Button
                                onClick={() => handleAreYouSure(areYouSure)}
                                className={classes.button}>
                                Yes
                            </Button>
                            <Button onClick={() => setDisplayAcceptRejectModal(false)}
                                className={classes.button}>
                                No
                            </Button>
                        </Box>
                    </div>
                </Modal>
            }
        </div >
    )
}