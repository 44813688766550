import React, { useContext, useEffect, useRef, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { Button, Checkbox, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Input, InputLabel, LinearProgress, Radio, RadioGroup, TextField, Typography, useTheme } from "@mui/material"
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Alert, Box } from '@mui/material';
import { KeyboardArrowRight } from '@mui/icons-material';
import authHeader from '../../services/auth-header';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from '../../contexts/UserContext';
import { makeStyles } from '@mui/styles';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

const useStyles = makeStyles((theme) => ({
    radioButtons: {
        marginTop: 30,
        marginBottom: 20,
        display: "block",
    },
    shippingButtons: {
        marginBottom: 15
    },
    textWithIcon: {
        display: 'flex',
        gap: '10px',
    },
    checkIcon: {
        color: theme.palette.primary.main,
    },
}));

export const StripeView = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const navigate = useNavigate();
    const { user, setUser } = useContext(UserContext);

    const [accountHolderName, setAccountHolderName] = useState("");
    const [routingNumber, setRoutingNumber] = useState("");
    const [accountNumber, setAccountNumber] = useState("");
    const [last4, setLast4] = useState();
    const [tosAccepted, setTosAccepted] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [loadingStripe, setLoadingStripe] = useState(false);
    const [stripeError, setStripeError] = useState();
    const [editStripeMode, setEditStripeMode] = useState(true);
    const [isStripeUpdated, setIsStripeUpdated] = useState(false);
    const isMounted = useRef(true);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (user && user.stripeAccountId) {
            setEditStripeMode(false);
        }
    }, [user]);

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const createStripeAccount = async () => {
        setLoadingStripe(true);
        setStripeError();

        try {
            const currentUser = JSON.parse(localStorage.getItem('user'));

            const response = await axios.post("/api/sellers/createStripeAccount", {
                user: currentUser,
                accountNumber: accountNumber,
                routingNumber: routingNumber,
                accountHolderName: accountHolderName,
                last4: last4,
                dob: selectedDate,
            }, {
                headers: authHeader(),
            });

            setLoadingStripe(false);
            const updatedUser = response.data.message;

            setUser(updatedUser);
            localStorage.setItem('user', JSON.stringify(updatedUser));

            if (updatedUser.status === 'registered') {
                navigate('/dashboard');
            }

        } catch (error) {
            setStripeError(error.response?.data?.error || error.message);
            setLoadingStripe(false);
            console.error("Error creating Stripe account:", error.response?.data?.error || error.message);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        if (routingNumber == '') {
            setStripeError("Please set a routing number")
        }

        if (accountNumber == '') {
            setStripeError("Please set an account number")
        }

        if (routingNumber && accountNumber) {
            createStripeAccount()
        }
    }

    return (
        <div>
            <br />
            {
                !editStripeMode ?
                    <>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h5">
                                    Stripe Information
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className={classes.textWithIcon}>
                                <CheckCircleOutlineIcon className={classes.checkIcon} />
                                <Typography variant='subtitle1'>Stripe Account Connected</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='subtitle2'>Account: ******{user.last4}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    endIcon={<KeyboardArrowRight />}
                                    color="primary"
                                    onClick={(e) => setEditStripeMode(true)}
                                >Edit</Button>
                            </Grid>
                        </Grid>
                    </>
                    :
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="h5">
                                Account Information
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                onChange={(e) => setAccountHolderName(e.target.value)}
                                label="Account Holder Name"
                                value={accountHolderName || ""}
                                variant="outlined"
                                color="primary"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                onChange={(e) => setRoutingNumber(e.target.value)}
                                label="Routing Number"
                                value={routingNumber || ""}
                                variant="outlined"
                                color="primary"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                onChange={(e) => setAccountNumber(e.target.value)}
                                label="Account Number"
                                value={accountNumber || ""}
                                variant="outlined"
                                color="primary"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Last 4 SSN"
                                onChange={(e) => setLast4(e.target.value)}
                                value={last4 || ""}
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Date of Birth"
                                    format="MM/dd/yyyy"
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                            {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    id="date-picker-dialog"
                                    label="Date of Birth"
                                    format="MM/dd/yyyy"
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    variant="outlined"
                                    fullWidth
                                />
                            </MuiPickersUtilsProvider> */}
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={tosAccepted}
                                        onChange={(e) => setTosAccepted(e.target.checked)}
                                    />
                                }
                                label={
                                    <span>
                                        I accept the <a href="https://stripe.com/us/connect-account/legal" target="_blank">Stripe's terms of service</a>.
                                    </span>
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {loadingStripe && <LinearProgress />}
                            {stripeError && <Alert severity='error'>{stripeError}</Alert>}
                            <br />
                            <Button
                                type="submit"
                                variant="contained"
                                endIcon={<KeyboardArrowRight />}
                                color="primary"
                                disabled={!tosAccepted}
                                onClick={(e) => handleSubmit(e)}
                            >Save</Button>
                        </Grid>
                    </Grid>
            }
        </div>
    )
}