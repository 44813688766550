import { AppBar, Box, Tab, Toolbar, Typography } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import React from "react";
import Login from "./Login";
import Signup from "./Signup";
import { useParams } from "react-router-dom";
import CreatePassword from "./CreatePassword";
import { makeStyles } from "@mui/styles";
import { theme } from "../../styles/theme";

const Authenticate = () => {
    const { id } = useParams();

    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <AppBar position="fixed">
                <Toolbar>
                    <Typography variant='h5'>
                        {"The Angling Marketplace"}
                    </Typography>
                </Toolbar>
                <Toolbar style={{ backgroundColor: 'primary' }}>
                    <Typography variant='h6'>
                        {"Seller Dashboard"}
                    </Typography>
                </Toolbar>
            </AppBar>
            <div>
                {id ?
                    <CreatePassword id={id} /> :
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} textColor="inherit" TabIndicatorProps={{
                                style: {
                                    backgroundColor: "#215732"
                                }
                            }}>
                                <Tab label="Login" value="1" />
                                <Tab label="Signup" value="2" />
                            </TabList>
                        </Box>
                        <TabPanel value="1"><Login /></TabPanel>
                        <TabPanel value="2"> <Signup /></TabPanel>
                    </TabContext>
                }
            </div>
        </>
    );
}

export default Authenticate;