import React, { useState, useContext, Component, useEffect } from "react";
import { Button, FormControlLabel, Grid, Link, TextField, Typography, useTheme } from "@mui/material";
import { Alert } from "@mui/material";
import { Checkbox } from "@mui/material";
import axios from "axios";
import jwtDecode from 'jwt-decode';
import { UserContext } from "../../contexts/UserContext";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    field: {
        marginTop: 20,
        marginBottom: 20,
        display: "block"
    }
})

const Login = () => {
    const theme = useTheme();
    const classes = useStyles();
    const navigate = useNavigate();
    const { user, setUser } = useContext(UserContext);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState();
    const [agreedToTerms, handleAgreeToTerms] = useState(false);

    const handleLogin = async (e) => {
        e.preventDefault();
        if (!agreedToTerms) {
            setMessage("You must agree to the Terms and Conditions")
            return;
        }
        try {
            const response = await axios.post('/api/users/login', { email, password });
            const token = response.data.token
            localStorage.setItem('token', token);

            // TODO: make secret key an env variable
            const decodedToken = jwtDecode(token, 'anglingmarketplace');
            const userId = decodedToken.id
            await axios.get(`/api/users/${userId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((userResponse) => {
                let curUser = userResponse.data;
                setUser(userResponse.data);
                localStorage.setItem('user', JSON.stringify(curUser));
                navigate(curUser.isAdmin ? "/admin" : "/dashboard");
            })

        } catch (error) {
            setMessage("Incorrect email or password");
            console.error(error);
        }
    }

    return (
        <form onSubmit={handleLogin}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    {
                        message &&
                        <Alert severity="error">
                            {message}
                        </Alert>
                    }
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className={classes.field}
                        label="Email"
                        variant="outlined"
                        color="primary"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        type="password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className={classes.field}
                        label="Password"
                        variant="outlined"
                        color="primary"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormControlLabel
                        control={<Checkbox
                            onChange={(e) => handleAgreeToTerms(e.target.checked)}
                            style={{
                                color: theme.palette.primary.main,
                            }}
                        />}
                        label={
                            <>
                                <Typography>I have read, understand and agree to the&nbsp;
                                    <Link target="_blank" href="http://subadmin.theanglingmarketplace.com/terms-and-conditions.pdf">
                                        Terms and Conditions of The Angling Marketplace
                                    </Link>
                                </Typography>
                            </>}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ margin: "15px 0px" }}
                        onClick={(e) => handleLogin(e)}
                    >Login</Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default Login;