import React from "react";
import AdminSidenavView from "./AdminSidenavView";

// Container Component is for entry 
// - fetch data & pass props to view 
export function AdminSidenav(props) {
    return (
        <div>
            <AdminSidenavView {...props}/>
        </div>
    );
}