import React, { useContext, useEffect, useState } from 'react';
import { Button, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Input, InputLabel, Radio, RadioGroup, TextField, Tooltip, Typography} from "@mui/material"
import Card from '../../components/Card';
import { Box } from '@mui/material';
import { KeyboardArrowRight } from '@mui/icons-material';
import ProfileCompletionPopup from './ProfileCompletionPopup';
import { PersonalView } from './PersonalView';
import authHeader from '../../services/auth-header';
import axios from 'axios';
import { UserContext } from '../../contexts/UserContext';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    radioButtons: {
        marginBottom: 20,
        display: "block",
    },
    shippingButtons: {
        marginBottom: 15
    }
})

export const ListingOptionsView = (props) => {
    const classes = useStyles();
    const { user, setUser } = useContext(UserContext);
    const [sellerName, setSellerName] = useState('')
    const [sellerEmail, setSellerEmail] = useState(user.email)
    const [sellerNameError, setSellerNameError] = useState(false)
    const [shippingInfo, setShippingInfo] = useState("usps");
    const [otherShippingValue, setOtherShippingValue] = useState("");
    const [showCompleteProfile, setShowCompleteProfile] = useState(false);

    const uspsLabelText = "First Class shipping inside the United States is calculated using USPS. International buyers pay actual USPS postage";
    const fedexLabelText = "First Class shipping inside the United States is calculated using FedEx. International buyers pay actual FedEx postage";

    useEffect(() => {
        if (user.status !== 'registered') {
            setShowCompleteProfile(true);
        }
        if (user.sellerName !== null) {
            setSellerName(user.sellerName);
        }
        if (user.shippingInfo === 'fedex' || user.shippingInfo === 'usps') {
            setShippingInfo(user.shippingInfo);
            setOtherShippingValue("");
        } else {
            setShippingInfo("other");
            setOtherShippingValue(user.shippingInfo);
        }
    }, []);

    const handleShippingInfoChange = (valueOrEvent) => {
        const value = typeof valueOrEvent === 'string' ? valueOrEvent : valueOrEvent.target.value;
        setShippingInfo(value);
    };

    const handleOtherShippingChange = (e) => {
        e.stopPropagation();
        const value = e.target.value;
        setOtherShippingValue(value);
    };

    const handleSubmitListingOptions = (e) => {
        try {
            console.log((shippingInfo === "other") ? otherShippingValue : shippingInfo)
            axios.put("/api/sellers/updateListingOptions", {
                id: user.id,
                sellerName: sellerName,
                shippingInfo: (shippingInfo === "other") ? otherShippingValue : shippingInfo,
            }, {
                headers: authHeader(),
            }).then((res) => {
                setUser(res.data.message);
                localStorage.setItem('user', JSON.stringify(res.data.message));
            });
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div>
            <Card
                content={{
                    title: "Listing Options",
                    body: <>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    onChange={(e) => setSellerName(e.target.value)}
                                    value={sellerName || ""}
                                    className={classes.field}
                                    label="Seller Name"
                                    variant="outlined"
                                    color="primary"
                                    fullWidth
                                    required
                                    error={sellerNameError}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Tooltip title="To change email, send email to info@theanglingmarketplace.com">
                                    <TextField
                                        value={sellerEmail || ""}
                                        variant="outlined"
                                        color="primary"
                                        fullWidth
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        label="Seller Email"
                                    />
                                </Tooltip>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl className={classes.radioButtons}>
                                    <FormLabel className={classes.radioButtons}>
                                        Shipping Terms
                                    </FormLabel>

                                    <RadioGroup value={shippingInfo} onChange={handleShippingInfoChange}>
                                        <Grid container direction="column">

                                            <Grid container alignItems="center" spacing={2}>
                                                <Grid item>
                                                    <FormControlLabel
                                                        className={classes.shippingButtons}
                                                        value="other"
                                                        control={<Radio />}
                                                    />
                                                </Grid>
                                                <Grid item xs>
                                                    <TextField
                                                        value={otherShippingValue || ""}
                                                        onChange={handleOtherShippingChange}
                                                        onFocus={(e) => {
                                                            e.stopPropagation();
                                                            handleShippingInfoChange("other");
                                                        }}
                                                        className={classes.field}
                                                        label="Defualt Shipping Terms"
                                                        variant="outlined"
                                                        color="primary"
                                                        multiline
                                                        minRows={4}
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </RadioGroup>
                                </FormControl>

                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    endIcon={<KeyboardArrowRight />}
                                    onClick={(e) => handleSubmitListingOptions(e)}
                                    color="primary"
                                >Save</Button>
                            </Grid>
                        </Grid>
                    </>
                }}
            />
        </div>
    )
}