import React, { useContext, useEffect, useState } from "react"; 
import { Sidenav } from "../../components/Sidenav/Sidenav";
import { TopMenu } from "../../components/TopMenu/TopMenu";
import AdminSidenav from "../../components/AdminSideNav";
import { UserContext } from "../../contexts/UserContext";
import { PostAuctionSyncView } from "./PostAuctionSyncView";


export const PostAuctionSync = () => {
    const {user, setUser} = useContext(UserContext);

    return (
        <PostAuctionSyncView />
    );
};