import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, LinearProgress, List, ListItem, ListItemText, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import UpcomingAuctionDropdown from "../../components/AuctionDropdown/UpcomingAuctionDropdown";
import AllAuctionDropdown from "../../components/AuctionDropdown/AllAuctionDropdown";
import axios from "axios";
import authHeader from "../../services/auth-header";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    dialog: {
        padding: '20px',
    },
});

export const SyncContacts = ({ selectedSyncAuction }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [registrations, setRegistrations] = useState({returnUsers: [], newUsers: []});
    const [open, setOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState({});

    const pullRegistrations = async () => {
        try {
            setLoading(true)
            const response = await axios.get(`/api/auctions/${selectedSyncAuction.id}/pullRegistrations`, {
                headers: authHeader(),
            });
            console.log(response.data)
            setRegistrations(response.data);
            setLoading(false)
        } catch (error) {
            console.error("Error updating auction:", error.response ? error.response.data : error);
        }
    }

    const handleSaveNewUser = async (user) => {
        try {
            user.status="user"
            const response = await axios.post(`/api/users/createUser`, user, {
                headers: authHeader()
            });
            
            const createdUser = response.data;

            setRegistrations(prevState => {
                const updatedNewUsers = prevState.newUsers.filter(u => u.email !== createdUser.email);
                const updatedReturnUsers = [...prevState.returnUsers, createdUser];

                return {
                    newUsers: updatedNewUsers,
                    returnUsers: updatedReturnUsers
                };
            });

            handleClose()
        } catch (error) {
            console.error("Error updating auction:", error.response ? error.response.data : error);
        }
    }

    const handleListItemClick = (user) => {
        setSelectedUser(user);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Grid container xs={12} spacing={2} alignItems="center">
            {
                loading &&
                (
                    <Grid item xs={12}>
                        <LinearProgress />
                    </Grid>
                )
            }
            <Grid item xs={12}>
                {
                    (registrations.returnUsers.length > 0 || registrations.newUsers.length > 0) ?
                        <>
                            <List>
                                {registrations.returnUsers.map((user) => (
                                    <ListItem
                                        key={user.id}
                                        button
                                        onClick={() => handleListItemClick(user)}
                                    >
                                        <ListItemText>
                                            <Alert severity='success'>
                                                <strong>Existing User: </strong>
                                                {user.firstName} {user.lastName} - {user.email}
                                            </Alert>
                                        </ListItemText>
                                    </ListItem>
                                ))}
                                {registrations.newUsers.map((user) => (
                                    <ListItem
                                        key={user.id}
                                        button
                                        onClick={() => handleListItemClick(user)}
                                    >
                                        <ListItemText>
                                            <Alert severity='warning'>
                                                <strong>New User: </strong>
                                                {user.firstName} {user.lastName} - {user.email}
                                            </Alert>
                                        </ListItemText>
                                    </ListItem>
                                ))}
                            </List>

                        </> :
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={pullRegistrations}
                        >Pull Registrations</Button>
                }
                <Dialog open={open} onClose={handleClose} classes={{ paper: classes.dialog }}
                >
                    <DialogTitle>User Details</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <p><strong>Name:</strong> {selectedUser.firstName} {selectedUser.lastName}</p>
                            <p><strong>Email:</strong> {selectedUser.email}</p>
                            <p><strong>Phone Number:</strong> {selectedUser.phoneNumber}</p>
                            <p><strong>Location:</strong> {selectedUser.city}, {selectedUser.state}</p>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={(e) => handleSaveNewUser(selectedUser)} color="primary" variant="contained">
                            Save New User
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        </Grid>
    );
};