import React, { useEffect, useState } from "react";
import RouterConfig from "./navigation/RouterConfig";

import { makeStyles } from "@mui/styles";
import { LinearProgress } from "@mui/material";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: 'white !important',
        padding: 0,
        margin: 0
    },
    page: {
        margin: theme.spacing(3),
        marginTop: theme.custom.appBarMargin
    },
}));

const BaseApp = () => {
    const classes = useStyles();
    const [user, setUser] = useState();
    const [isHydrating, setIsHydrating] = useState(true);

    useEffect(() => {
        const persistedUser = localStorage.getItem('user');
        if (persistedUser) {
            setUser(JSON.parse(persistedUser));
        }
        setIsHydrating(false);
        const loadGoogleMapsScript = () => {
            if (window.google) {
                return;
            }

            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = 'https://maps.googleapis.com/maps/api/js?key=' + process.env.REACT_APP_TAM_GOOGLE_API_KEY + '&libraries=places';
            script.async = true;
            script.defer = true;
            document.body.appendChild(script);
        };

        loadGoogleMapsScript();
    }, []);

    return (
        <div className={classes.page}>
            {
                isHydrating ?
                    <LinearProgress /> :
                    <RouterConfig />
            }
        </div>
    );
}

export default BaseApp;
