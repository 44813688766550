import React, { useContext, useState } from 'react';
import menuItems from '../../navigation/MenuItems';
import { useNavigate } from 'react-router';
import { UserContext } from '../../contexts/UserContext';
import { makeStyles, useTheme } from '@mui/styles';
import { Divider, Drawer, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    welcome: {
        paddingTop: 20,
        paddingBottom: 20
    },
    toggleButton: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        }
    }
}));

const SidenavView = props => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = props.isMobile;
    const drawerOpen = props.drawerOpen;
    const handleDrawerToggle = props.handleDrawerToggle;

    const { user, setUser } = useContext(UserContext);
    const navigate = useNavigate()
    const userStatus = user.status;

    return (
        <>
            <Drawer
                className={classes.drawer}
                variant={isMobile ? "temporary" : "permanent"}  
                open={isMobile ? drawerOpen : true}
                anchor="right"
                classes={{ paper: classes.drawerPaper }}
                onClose={handleDrawerToggle}
            >
                <Grid container alignItems="center" className={classes.welcome} onClick={() => navigate("/settings")}>
                    <Grid container item xs={4} justifyContent="flex-end">
                        <AccountCircleIcon color="primary" style={{ fontSize: 64, paddingRight: 12 }} />
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="h5">
                            Welcome,
                        </Typography>
                        <Typography variant="h6">
                            {props.firstName}
                        </Typography>
                    </Grid>
                </Grid>
                <Divider variant="middle" />
                {
                    userStatus !== 'registered' ?
                        <List>
                            <ListItem
                                button
                                key='settings'
                                onClick={() => navigate('/settings')}
                            >
                                <ListItemIcon><SettingsApplicationsIcon /></ListItemIcon>
                                <ListItemText primary={"Settings"} />
                            </ListItem>
                        </List>
                        : <List>
                            {menuItems.map((item) => (
                                <ListItem
                                    button
                                    key={item.text}
                                    onClick={() => navigate(item.path)}
                                >
                                    <ListItemIcon>{item.icon}</ListItemIcon>
                                    <ListItemText primary={item.text} />
                                </ListItem>
                            ))}
                        </List>
                }
            </Drawer>
        </>
    )
}

export default SidenavView;