import React, { useContext, useEffect, useState } from 'react';
import { Button, Modal, Box, TextField, Typography, Grid, Alert } from '@mui/material';
import DragAndDropArea from '../DragAndDrop/DragAndDropArea';
import auctionService from '../../services/auction.service';
import axios from 'axios';
import { UserContext } from '../../contexts/UserContext';
import authHeader from '../../services/auth-header';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    maxHeight: '90vh',
    overflow: 'scroll'
};

const EditItemModal = ({ open, onClose, itemId, auction }) => {
    const { user, setUser } = useContext(UserContext);

    const [itemData, setItemData] = useState({
        id: '',
        title: '',
        description: '',
        shippingInfo: '',
        catalogOrder: null
    });

    const [images, setImages] = useState([]);
    const [oldImages, setOldImages] = useState([]);
    const [loadingUpdate, setLoadingUpdate] = useState(false);

    useEffect(() => {
        if (open && itemId) {
            fetchItemData(itemId);
        }
    }, [open, itemId]);

    function extractKeyFromUrl(url) {
        const urlParts = url.split('/');
        return urlParts.slice(3).join('/');
    }

    const handleChange = (event) => {
        setItemData({
            ...itemData,
            [event.target.name]: event.target.value,
        });
    };

    const handleCancel = () => {
        setImages([]);
        setItemData({});
        onClose();
    };

    const handleSave = async () => {
        setLoadingUpdate(true);
        try {
            const response = await axios.put(`/api/items/update-item/${itemId}`, itemData, {
                headers: authHeader()
            });
            console.log(response.data.message);
        } catch (error) {
            console.error('Error in handleSave')
        }
        if (images !== oldImages) {
            try {
                const newImageUrls = new Set(images.map(image => image.preview));
                const imagesToDelete = oldImages.filter(oldImage => !newImageUrls.has(oldImage.preview));

                for (const deleteImage of imagesToDelete) {
                    await axios.delete('/api/items/delete-image', {
                        headers: authHeader(),
                        data: { imageKey: deleteImage.id, imageId: deleteImage.dbId, auctionId: auction.id }
                    });
                }

                // Handle New Image Uploads 
                const uploadedImageUrls = [];
                for (let i = 0; i < images.length; i++) {
                    const image = images[i];
                    if (image.file instanceof File) {
                        const newFileName = `${itemData.catalogOrder}_${i + 1}_new`; // Adjust the naming logic as required
                        console.log(newFileName)
                        const presignedResponse = await axios.post('/api/items/generate-presigned-url', {
                            fileName: newFileName,
                            fileType: image.file.type,
                            auctionId: auction.id
                        });

                        const presignedUrl = presignedResponse.data.presignedUrl;
                        await axios.put(presignedUrl, image.file, {
                            headers: {
                                'Content-Type': image.file.type,
                                'Cache-Control': 'no-cache'
                            }
                        });

                        const publicUrl = presignedUrl.split('?')[0];
                        image.preview = publicUrl;
                        image.id = newFileName;
                        uploadedImageUrls.push(publicUrl);
                    }
                }

                const imageData = images.map((image, index) => ({
                    url: image.preview,
                    isNew: image.preview.includes("_new"),
                    order: index + 1
                }));

                const updatePayload = {
                    images: imageData,
                    auctionId: auction.id,
                    itemId: itemData.id
                };

                await axios.put('/api/items/update-images', updatePayload, {
                    headers: authHeader()
                });

            } catch (error) {
                console.error('Error in handleSave:', error);
            } finally {
                setLoadingUpdate(false);
                setItemData({});
                setImages([]);
                onClose();
            }
        }
        setLoadingUpdate(false);
        setItemData({});
        setImages([]);
        onClose();
        window.location.reload();
    };

    const fetchItemData = async (itemId) => {
        try {
            const response = await axios.get(`/api/items/${itemId}`);
            const item = response.data;

            setItemData({
                id: item.id,
                title: item.short_description,
                description: item.full_description,
                shippingInfo: item.shipping_information,
                catalogOrder: item.catalog_order
            });

            const fetchedImages = item.photos.map(image => ({
                dbId: image.id,
                id: `${image.url.split('/').pop()}`,
                preview: image.url,
                file: {}
            }));

            setOldImages(fetchedImages);
            setImages(fetchedImages);

        } catch (error) {
            console.error('Error fetching item data:', error);
        }
    };

    const onCloseConditional = () => {
        if (!loadingUpdate) {
            onClose()
        }
    }

    return (
        <Modal
            open={open}
            onClose={onCloseConditional}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='h5'>Edit Lot</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            required
                            name="title"
                            label="Title"
                            value={itemData.title}
                            onChange={handleChange}
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DragAndDropArea
                            images={images}
                            setImages={setImages}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            name="description"
                            label="Full Description"
                            value={itemData.description}
                            onChange={handleChange}
                            margin="normal"
                            multiline={true}
                            rows={4}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            name="shippingInfo"
                            label="Shipping Information"
                            value={itemData.shippingInfo}
                            onChange={handleChange}
                            margin="normal"
                            multiline={true}
                            rows={4}
                        />
                    </Grid>
                    {
                        loadingUpdate &&
                        <Grid item xs={12}>
                            <Alert severity='error'>Do not close or refresh this page until item is saved</Alert>
                        </Grid>
                    }
                    <Grid item container spacing={2}>
                        <Grid item>
                            <Button disabled={loadingUpdate ? true : false} variant='contained' onClick={handleSave}>Save</Button>
                        </Grid>
                        <Grid item>
                            <Button variant='contained' onClick={handleCancel}>Cancel</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
};

export default EditItemModal;
