import React, { useState, useRef } from 'react';
import { Button, Box, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/styles';

const DragAndDropArea = ({ images, setImages }) => {
    const theme = useTheme();
    const fileInputRef = useRef();
    const dragItem = useRef();
    const dragOverItem = useRef();

    const handleDragStart = (e, index) => {
        dragItem.current = index;
    };

    const handleDragEnter = (e, index) => {
        dragOverItem.current = index;
    };

    const handleDragEnd = () => {
        const reorderedImages = Array.from(images);
        const draggedItem = reorderedImages.splice(dragItem.current, 1)[0];
        reorderedImages.splice(dragOverItem.current, 0, draggedItem);

        dragItem.current = null;
        dragOverItem.current = null;
        setImages(reorderedImages);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const files = Array.from(e.dataTransfer.files)
            .filter(file => file.type.startsWith('image/'))
            .map(file => ({
                file: file,
                id: `${file.name}`,
                preview: URL.createObjectURL(file)
            }));

        setImages(prevImages => [...prevImages, ...files]);
    };

    const handleDeleteImage = (index) => {
        setImages(images => images.filter((_, i) => i !== index));
    };

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files)
            .filter(file => file.type.startsWith('image/'))
            .map(file => ({
                id: `${file.name}`,
                file, // store the actual file object
                preview: URL.createObjectURL(file)
            }));
    
        setImages(prevImages => [...prevImages, ...files]);
    };

    const handleClick = () => {
        fileInputRef.current.click();
    };

    return (
        <>
            <Box
                sx={{
                    border: '2px solid grey',
                    padding: 2,
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 2,
                    minHeight: 100,
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    '&:hover': {
                        borderColor: theme.palette.primary.main,
                    }
                }}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
            >
                {images.length === 0 && (
                    <Typography variant="subtitle1" sx={{ color: 'grey' }}>
                        Drag and drop images here or click "Upload Images" button
                    </Typography>
                )}                {images.map((image, index) => (
                    <Box
                        key={image.id}
                        draggable
                        onDragStart={(e) => handleDragStart(e, index)}
                        onDragEnter={(e) => handleDragEnter(e, index)}
                        onDragEnd={handleDragEnd}
                        sx={{
                            position: 'relative',
                            width: 100,
                            height: 100,
                            overflow: 'hidden'
                        }}                    >
                        <IconButton
                            onClick={() => handleDeleteImage(index)}
                            sx={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                zIndex: 2,
                                backgroundColor: 'rgba(255, 255, 255, 0.7)', // Semi-transparent white background
                                '&:hover': {
                                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                                }
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <img src={image.preview} alt={`img-${index}`} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    </Box>
                ))}
                <input
                    type="file"
                    accept="image/*"
                    multiple
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                />

            </Box>
            <Button variant='contained' onClick={handleClick} sx={{ mt: 2 }}>
                Upload Images
            </Button>
        </>
    );
};

export default DragAndDropArea;
