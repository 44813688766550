import React from "react";
import { LinkRoute } from "../components/LinkRoute/LinkRoute.js";
import { Typography } from "@mui/material";

export const NotFound = () => {
    return (
        <>
            <LinkRoute to={'/'}>Home</LinkRoute>
            <Typography variant="h2">404 Page not found!</Typography>
        </>
    );
};