import React, { Component, useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Link, Outlet, Navigate } from "react-router-dom";
import { NotFound } from "./NotFound";
import Upcoming from "../pages/Upcoming";
import Previous from "../pages/Previous";
import Settings from "../pages/Settings";
import Authenticate from "../pages/Authentication/Authenticate";
import { AdminDashboard } from "../pages/Dashboard/AdminDashboard";
import { SellerDashboard } from "../pages/Dashboard/SellerDashboard";
import ApproveSellers from "../pages/ApproveSellers";
import { AdminSidenav } from "../components/AdminSideNav/AdminSidenav";
import { TopMenu } from "../components/TopMenu/TopMenu";
import { Sidenav } from "../components/Sidenav/Sidenav";
import { UserContext } from "../contexts/UserContext";
import { makeStyles, useTheme } from "@mui/styles";
import { useMediaQuery } from "@mui/material";
import { PostAuctionSync } from "../pages/PostAuctionSync/PostAuctionSync";
import { UserDatabase } from "../pages/UserDatabase/UserDatabase";
import { PreAuctionSync } from "../pages/PreAuctionSync/PreAuctionSync";


export const RouterConfig = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const useStyles = makeStyles(theme => ({
        content: {
            width: isMobile ? 'calc(100vw - 45px)' : `calc(100vw - ${theme.custom.drawerWidth}px)`,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
    }));

    const classes = useStyles();
    const { user, setUser } = useContext(UserContext);
    const isAuthenticated = !!user;
    const isAdmin = user?.isAdmin;
    const [drawerOpen, setDrawerOpen] = useState(false);

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const AdminRoute = () => {
        return isAuthenticated && isAdmin ?
            <div className={classes.content}>
                <AdminSidenav
                    firstName={user.firstName}
                    isMobile={isMobile}
                    drawerOpen={drawerOpen}
                    handleDrawerToggle={handleDrawerToggle}
                />
                <TopMenu
                    displayName={user.displayName}
                    isMobile={isMobile}
                    isAdmin={true}
                    handleDrawerToggle={handleDrawerToggle}
                />
                <Outlet />
            </div>
            : <Navigate to="/" />;
    };

    const PrivateRoute = () => {
        return isAuthenticated ?
            <div className={classes.content}>
                <Sidenav
                    firstName={user.firstName}
                    isMobile={isMobile}
                    drawerOpen={drawerOpen}
                    handleDrawerToggle={handleDrawerToggle} 
                />
                <TopMenu
                    displayName={user.displayName}
                    isMobile={isMobile}
                    isAdmin={false}
                    handleDrawerToggle={handleDrawerToggle}
                />
                <Outlet />
            </div>
            : <Navigate to="/login" />;
    };

    const DefaultRoute = ({ isAuthenticated, isAdmin }) => {
        if (isAuthenticated && isAdmin) {
            return <Navigate to="/admin" replace />;
        } else if (isAuthenticated && !isAdmin) {
            return <Navigate to="/dashboard" replace />;
        } else {
            return <Navigate to="/login" replace />;
        }
    };

    return (
        <div>
            <Router>
                <Routes>
                    {/* Authenticate links */}
                    <Route path="/" element={<DefaultRoute isAuthenticated={isAuthenticated} isAdmin={isAdmin} />} />
                    <Route path="/login/:id" element={<Authenticate />} />
                    <Route path="/login" element={<Authenticate />} />

                    {/* Admin links */}
                    <Route path="admin" element={<AdminRoute />}>
                        <Route index element={<AdminDashboard />} />
                        <Route path="approve-sellers" element={<ApproveSellers />} />
                        <Route path="pre-auction-sync" element={<PreAuctionSync />} />
                        <Route path="post-auction-sync" element={<PostAuctionSync />} />
                        <Route path="upcoming" element={<Upcoming />} />
                        <Route path="user-database" element={<UserDatabase />} />
                    </Route>

                    {/* Seller links */}
                    <Route path="" element={<PrivateRoute />}>
                        <Route index element={<SellerDashboard />} />
                        <Route path="dashboard" element={<SellerDashboard />} />
                        <Route path="upcoming" element={<Upcoming />} />
                        <Route path="previous" element={<Previous />} />
                        <Route path="settings" element={<Settings />} />
                    </Route>

                    {/* Not found page */}
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </Router>
        </div>
    );
};

export default RouterConfig;