import React, { useContext, useEffect, useState } from 'react';
import { Button, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Input, InputLabel, Radio, RadioGroup, TextField, Tooltip, Typography } from "@mui/material"
import Card from '../../components/Card';
import { Box } from '@mui/material';
import { KeyboardArrowRight } from '@mui/icons-material';
import ProfileCompletionPopup from './ProfileCompletionPopup';
import { PersonalView } from './PersonalView';
import authHeader from '../../services/auth-header';
import axios from 'axios';
import { ListingOptionsView } from './ListingOptionsView';
import { UserContext } from '../../contexts/UserContext';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    radioButtons: {
        marginBottom: 20,
        display: "block",
    },
    shippingButtons: {
        marginBottom: 15
    }
})

export const SettingsView = (props) => {
    const classes = useStyles();
    const {user, setUser} = useContext(UserContext);
    const [sellerName, setSellerName] = useState('')
    const [sellerEmail, setSellerEmail] = useState(user.email)
    const [sellerNameError, setSellerNameError] = useState(false)
    const [shippingInfo, setShippingInfo] = useState("usps");
    const [otherShippingValue, setOtherShippingValue] = useState("");
    const [showCompleteProfile, setShowCompleteProfile] = useState(false);

    useEffect(() => {
        if (user.status !== 'registered') {
            setShowCompleteProfile(true);
        } else {
            setShowCompleteProfile(false);
        }
        if (user.sellerName !== null) {
            setSellerName(user.sellerName);
        }
        if (user.shippingInfo === 'fedex' || user.shippingInfo === 'usps') {
            setShippingInfo(user.shippingInfo);
            setOtherShippingValue("");
        } else {
            setShippingInfo("other");
            setOtherShippingValue(user.shippingInfo);
        }
    }, []);

    const handleShippingInfoChange = (valueOrEvent) => {
        const value = typeof valueOrEvent === 'string' ? valueOrEvent : valueOrEvent.target.value;
        setShippingInfo(value);
    };

    const handleOtherShippingChange = (e) => {
        e.stopPropagation();
        const value = e.target.value;
        setOtherShippingValue(value);
    };

    const handleSubmitListingOptions = (e) => {
        try {
            console.log((shippingInfo === "other") ? otherShippingValue : shippingInfo)
            axios.put("/api/sellers/updateListingOptions", {
                id: user.id,
                sellerName: sellerName,
                shippingInfo: (shippingInfo === "other") ? otherShippingValue : shippingInfo,
            }, {
                headers: authHeader(),
            }).then((res) => { 
                let curUser = JSON.stringify(res.data.message);
                localStorage.setItem('user', curUser);
            });
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div>
            <Grid container spacing={2} className={classes.cardContainer}>
                <Grid item md={6} sm={12}>
                    <ListingOptionsView user={props.user} />
                </Grid>
                <Grid item md={6} sm={12}>
                    <PersonalView user={props.user} />
                </Grid>
            </Grid>
            {showCompleteProfile && <ProfileCompletionPopup setShowCompleteProfile={setShowCompleteProfile} />}
        </div>
    )
}