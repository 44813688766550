import React from 'react';
import {Divider, Drawer, Grid, List, ListItem, ListItemIcon, ListItemText, Typography} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useNavigate } from 'react-router';
import adminMenuItems from '../../navigation/AdminMenuItems';
import { makeStyles } from '@mui/styles';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    welcome: {
        paddingTop: 20,
        paddingBottom: 20
    }
}));

const AdminSidenavView = props => {
    const classes = useStyles()
    const navigate = useNavigate()

    return (
        <Drawer
            className={classes.drawer}
            variant="permanent"
            anchor="right"
            classes={{ paper: classes.drawerPaper }}
        >
            <Grid container alignItems="center" className={classes.welcome} onClick={() => navigate("/settings")}>
                <Grid container item xs={4} justifyContent="flex-end">
                    <AccountCircleIcon color="primary" style={{ fontSize: 64, paddingRight: 12 }} />
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="h5">
                        Welcome,
                    </Typography>
                    <Typography variant="h6">
                        {props.firstName}
                    </Typography>
                </Grid>
            </Grid>
            <Divider variant="middle" />
            <List>
                {adminMenuItems.map((item) => (
                    <ListItem
                        button
                        key={item.text}
                        onClick={() => navigate(item.path)}
                    >
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.text}/>
                    </ListItem>
                ))}
            </List>
        </Drawer>
    )
}

export default AdminSidenavView;