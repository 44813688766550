import DashboardIcon from '@mui/icons-material/Dashboard';
import GavelIcon from '@mui/icons-material/Gavel';
import GroupIcon from '@mui/icons-material/Group';
import LoopIcon from '@mui/icons-material/Loop';
import ContactPageIcon from '@mui/icons-material/ContactPage';

const menuItems = [
    {
        text: "Dashboard",
        icon: <DashboardIcon color="primary" />,
        path: "/admin"
    }, 
    {
        text: "Approve Sellers",
        icon: <GroupIcon color="primary" />,
        path: "/admin/approve-sellers"
    },
    {
        text: "Pre Auction Sync",
        icon: <LoopIcon color="primary" />,
        path: "/admin/pre-auction-sync"
    }, 
    {
        text: "Post Auction Sync",
        icon: <LoopIcon color="primary" />,
        path: "/admin/post-auction-sync"
    }, 
    {
        text: "Upcoming Auctions",
        icon: <GavelIcon color="primary" />,
        path: "/admin/upcoming"
    },
    {
        text: "User Database",
        icon: <ContactPageIcon color="primary" />,
        path: "/admin/user-database"
    }
]

export default menuItems;