import React, { useContext, useEffect, useState } from 'react';
import { Button, Modal, Box, TextField, Typography, Grid } from '@mui/material';
import axios from 'axios';
import authHeader from '../../services/auth-header';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    maxHeight: '90vh',
    overflow: 'scroll'
};

const DeleteItemModal = ({ open, onClose, onConfirm, selectedItems, setSelectedItems, items, setItems, auction }) => {

    const deleteSelectedItems = async () => {
        try {
            const itemIdsToDelete = Array.from(selectedItems);
    
            await axios.delete('/api/items/delete', {
                headers: authHeader(),
                data: { itemIds: itemIdsToDelete, auctionId: auction.id }
            });

            const updatedItems = items.filter(item => !itemIdsToDelete.includes(item.id));
            setItems(updatedItems);
            setSelectedItems([]);
    
        } catch (error) {
            console.error('Error deleting items:', error);
        }
        onClose()
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="confirm-delete-title" variant="h6" component="h2">
                    Confirm Deletion
                </Typography>
                <Typography id="confirm-delete-description" sx={{ mt: 2 }}>
                    Are you sure you want to delete the selected items? This action cannot be undone.
                </Typography>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={deleteSelectedItems}>
                            Yes, Delete
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="outlined" color="primary" onClick={onClose}>
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
};

export default DeleteItemModal;
