import { createTheme } from '@mui/material/styles';

const drawerWidth = 285;
const appBarMargin = 152;

export const theme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#215732'
        },
        secondary: {
            main: '#d2c4a4',
        },
        background: {
            default: '#fafafa',
            paper: '#fafafa'
        },
        error: {
            main: '#942c0e'
        },
        warning: {
            light: '#ffac33', // Lighter warning color
            main: '#ff9800', // Main warning color
            dark: '#b26a00',  // Darker warning color
        },
        success: {
            light: '#76d275', // Lighter success color
            main: '#43a047', // Main success color
            dark: '#00701a',  // Darker success color
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },

    custom: {
        drawerWidth: drawerWidth,
        appBarMargin: appBarMargin
    }
});
