import React, { useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { Provider as ReduxProvider } from "react-redux";
import { store } from "./redux/store";
import "./App.css";
import { theme } from './styles/theme'; 
import { UserContext, UserProvider } from "./contexts/UserContext";
import BaseApp from "./BaseApp";
import { CssBaseline } from "@mui/material";

const App = () => {
    const [user, setUser] = useState();

    return (
        <UserProvider>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <ReduxProvider store={store}>
                    <BaseApp />
                </ReduxProvider>
            </ThemeProvider>
        </UserProvider>
    );
}

export default App;
