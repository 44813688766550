import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Input, InputLabel, Radio, RadioGroup, TextField, Typography } from "@mui/material"
import Card from '../../components/Card';
import { Box } from '@mui/material';
import { KeyboardArrowRight } from '@mui/icons-material';
import ProfileCompletionPopup from './ProfileCompletionPopup';
import { StripeView } from './StripeView';
import axios from 'axios';
import authHeader from '../../services/auth-header';
import { UserContext } from '../../contexts/UserContext';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    field: {
        marginTop: 20,
        display: "block",
    },
    radioButtons: {
        marginTop: 30,
        marginBottom: 20,
        display: "block",
    },
    shippingButtons: {
        marginBottom: 15
    }
})

export const PersonalView = (props) => {
    const classes = useStyles();
    const {user, setUser} = useContext(UserContext);

    const [sellerName, setSellerName] = useState('')
    const [sellerEmail, setSellerEmail] = useState('')
    const [sellerNameError, setSellerNameError] = useState(false)
    const [sellerEmailError, setSellerEmailError] = useState(false)
    const [paymentPreference, setPaymentPreference] = useState('check')
    const [sellerAddress, setSellerAddress] = useState([])
    const [showCompleteProfile, setShowCompleteProfile] = useState(false);
    const addressInputRef = useRef(null);

    const handleSubmitAddressChange = async (event) => {
        event.preventDefault();
        try {
            axios.put('/api/sellers/updatedSellerInformation', {
                user
            }, {
                headers: authHeader(),
            }).then((res) => {
                setUser(res.data.message);
                localStorage.setItem('user', JSON.stringify(res.data.message));
            })
        } catch (error) {
            console.error(error);
        }
    };

    const handleChange = (event) => {
        setUser({
            ...user,
            [event.target.name]: event.target.value
        });
        localStorage.setItem('user', JSON.stringify({
            ...user,
            [event.target.name]: event.target.value
        }));
    };

    return (
        <div>
            <Card
                content={{
                    title: "Address",
                    body: <>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    label="First Name"
                                    fullWidth
                                    name="firstName"
                                    variant="outlined"
                                    color="primary"
                                    value={user.firstName || ""}
                                    onChange={handleChange}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    label="Last Name"
                                    fullWidth
                                    name="lastName"
                                    variant="outlined"
                                    color="primary"
                                    value={user.lastName || ""}
                                    onChange={handleChange}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    label="Phone Number"
                                    fullWidth
                                    name="phoneNumber"
                                    variant="outlined"
                                    color="primary"
                                    value={user.phoneNumber || ""}
                                    onChange={handleChange}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    label="Address"
                                    id="address-input"
                                    fullWidth
                                    variant="outlined"
                                    color="primary"
                                    value={user.addressLine1 || ""}
                                    onChange={handleChange}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    label="Address Line 2"
                                    fullWidth
                                    name="addressLine2"
                                    variant="outlined"
                                    color="primary"
                                    value={user.addressLine2 || ""}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    label="City"
                                    fullWidth
                                    name="city"
                                    variant="outlined"
                                    color="primary"
                                    value={user.city || ""}
                                    onChange={handleChange}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="State/Province"
                                    fullWidth
                                    name="state"
                                    variant="outlined"
                                    color="primary"
                                    value={user.state || ""}
                                    onChange={handleChange}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Postal Code"
                                    fullWidth
                                    name="postalCode"
                                    variant="outlined"
                                    color="primary"
                                    value={user.postalCode || ""}
                                    onChange={handleChange}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Country"
                                    fullWidth
                                    name="country"
                                    variant="outlined"
                                    color="primary"
                                    value={user.country || ""}
                                    onChange={handleChange}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    endIcon={<KeyboardArrowRight />}
                                    color="primary"
                                    onClick={(e) => handleSubmitAddressChange(e)}
                                >Save</Button>
                            </Grid>
                        </Grid>
                        <StripeView user={props.user}/>
                    </>
                }}
            />
        </div>
    )
}