import React from 'react';
import menuItems from '../../navigation/MenuItems';
import { useNavigate } from 'react-router';
import { makeStyles } from '@mui/styles';
import { Card, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({

}));

const TableView = (props) => {
	const content = props.content;
	const classes = useStyles()
	const navigate = useNavigate()
	const rows = content.body
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	console.log(rows)

	return (
		<>
			<TableContainer component={Card}>
				<Table>
					<TableHead>
						<TableRow>
							{content.labels.map((label, index) => (
								<TableCell key={index} align={label.align}>{label.title}</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map((row, idx) => (
							<TableRow key={idx}>
								{row.cells.map((cell, index) => (
									<TableCell key={index} style={row.style} colSpan={row.columnSpan} align="left">{cell}</TableCell>
								))}
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			{(content.pagenation) ? <TablePagination
				rowsPerPageOptions={[10, 25, 100]}
				component="div"
				count={rows.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/> : <></>}
		</>
	)
}

export default TableView;