import React, { useContext } from "react"; 
import { UserContext } from "../../contexts/UserContext";
import { PreAuctionSyncView } from "./PreAuctionSyncView";


export const PreAuctionSync = () => {
    const {user, setUser} = useContext(UserContext);

    return (
        <PreAuctionSyncView />
    );
};