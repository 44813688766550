import React from "react";
import headerBackground from '../../assets/header.jpg';
import menuItems from "../../navigation/MenuItems";
import adminMenuItems from "../../navigation/AdminMenuItems";
import { useLocation } from "react-router";
import { makeStyles, useTheme } from "@mui/styles";
import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';

function getCurrentLocation(array, path) {
    return array.find((element) => {
        return element.path === path
    })
}

const useStyles = makeStyles(theme => ({
    pageName: {
        backgroundColor: theme.palette.primary.main,
    },
    subTitle: {
        fontSize: '12px'
    },
    header: {
        backgroundImage: `url(${headerBackground})`,
        backgroundSize: `cover`,
        backgroundRepeat: `no-repeat`,
        flexDirection: 'column',
        alignItems: 'flex-start',
        display: 'flex'
    },
    header2: {
        minHeight: `48px`,
        width: `100%`,
        backgroundColor: theme.palette.primary.main
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    menuButton: {
        marginLeft: 'auto',
    },
}));

const TopMenuView = props => {
    const classes = useStyles();
    const theme = useTheme();
    const location = useLocation();
    const isMobile = props.isMobile;
    const isAdmin = props.isAdmin;
    const handleDrawerToggle = props.handleDrawerToggle;
    const showMenuItems = isAdmin ? adminMenuItems : menuItems
    const curLocationName = getCurrentLocation(showMenuItems, location.pathname)

    return (
        <>
            <AppBar className={classes.header} position="fixed">
                <Toolbar>
                    <Typography variant='h5' className={classes.title}>
                        {props.displayName}
                    </Typography>
                </Toolbar>
                <Toolbar className={classes.header2}>
                    <Typography variant='h6'>
                        {curLocationName ? curLocationName.text : "Dashboard"}
                    </Typography>
                    {isMobile && (
                        <IconButton
                            className={classes.menuButton}
                            color="inherit"
                            onClick={handleDrawerToggle}
                        >
                            <MenuIcon />
                        </IconButton>
                    )}
                </Toolbar>
            </AppBar>

        </>
    )
}

export default TopMenuView;