import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../contexts/UserContext";
import { UserDatabaseView } from "./UserDataBaseView";
import axios from "axios";
import authHeader from "../../services/auth-header";


export const UserDatabase = () => {
    const { user, setUser } = useContext(UserContext);
    const [allUsers, setAllUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        const token = localStorage.getItem('token');
        axios.get("/api/users/allUsers", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((res) => {
            setAllUsers(res.data);
            console.log(res)
        }).catch((err) => {
            console.log("error getting all users");
            setAllUsers([]);
        })
}, [user]);

const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
};

const filteredUsers = allUsers.filter((user) => 
    user.firstName.toLowerCase().includes(searchQuery) ||
    user.lastName.toLowerCase().includes(searchQuery) || 
    user.email.toLowerCase().includes(searchQuery)
);

return (
    <UserDatabaseView
        filteredUsers={filteredUsers}
        handleSearchChange={handleSearchChange}
        searchQuery={searchQuery}
    />
);
};