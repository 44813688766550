import React, { useContext, useEffect, useState } from "react";
import { Button, Grid, Step, StepLabel, Stepper, Typography } from "@mui/material";
import AllAuctionDropdown from "../../components/AuctionDropdown/AllAuctionDropdown";
import axios from "axios";

export const PreAuctionSyncView = () => {
    const [auctionId, setAuctionID] = useState('');
    const [selectedAuction, setSelectedAuction] = useState(null);

    const handleAuctionChange = (auction) => {
        setSelectedAuction(auction);
    };

    const getAllLots = async () => {
        const url = `/api/auctions/${selectedAuction.id}/items/csv`;

        try {
            const response = await axios.get(url, { responseType: 'blob' });
            const blob = new Blob([response.data], { type: 'text/csv' });
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', 'lots.csv');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(downloadUrl);
        } catch (error) {
            console.error('Error downloading the file:', error);
        }
    }

    const getAllLotPhotos = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios({
                url: `/api/items/download-auction-files/${selectedAuction.id}`, 
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                method: 'GET',
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${selectedAuction.id}.zip`); 
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading files:', error);
        }
    }

    return (
        <Grid container direction="column" spacing={4} style={{ height: '100%' }}>
            <Grid item>
                <AllAuctionDropdown
                    selectedAuction={selectedAuction}
                    onAuctionChange={handleAuctionChange}
                />
            </Grid>
            <Grid item>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={getAllLots}
                >
                    Download Item Details CSV
                </Button>
            </Grid>
            <Grid item>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={true}
                    onClick={getAllLotPhotos}
                >
                    Download All Photos
                </Button>
            </Grid>
        </Grid>
    );
}