import React, { useContext, useEffect, useState } from 'react';
import { Button, Modal, Box, TextField, Typography, Grid, LinearProgress, Alert } from '@mui/material';
import DragAndDropArea from '../DragAndDrop/DragAndDropArea';
import auctionService from '../../services/auction.service';
import axios from 'axios';
import { UserContext } from '../../contexts/UserContext';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    maxHeight: '90vh',
    overflow: 'scroll'
};

// TODO: short description cant be null
// TODO: long description cant be null
const AddItemModal = ({ open, onClose, auction, items, setItems }) => {
    const { user, setUser } = useContext(UserContext);

    const [itemData, setItemData] = useState({
        title: '',
        description: '',
        shippingInfo: '',
        catalogOrder: null
    });

    const [images, setImages] = useState([]);
    const [loadingNewItem, setLoadingNewItem] = useState(false);

    useEffect(() => {
        const fetchNextItemNumber = async () => {
            if (auction) {
                try {
                    const nextItemNumber = await auctionService.getNextItemNumber(auction.id);
                    setItemData(prevData => ({
                        ...prevData,
                        catalogOrder: nextItemNumber,
                        sellerId: user.id
                    }));
                } catch (error) {
                    console.error("Error fetching next item number:", error.message);
                }
            }
        };

        if (open) {
            fetchNextItemNumber();
        }
    }, [open, auction]);

    const handleChange = (event) => {
        setItemData({
            ...itemData,
            [event.target.name]: event.target.value,
        });
    };

    const handleCancel = () => {
        setImages([]);
        setItemData({});
        onClose();
    };

    const handleSave = async () => {
        setLoadingNewItem(true);
        try {
            const imageUrls = await Promise.all(images.map(async (image, index) => {
                const newFileName = `${itemData.catalogOrder}_${index + 1}`;

                if (image.file instanceof File) {
                    const presignedResponse = await axios.post('/api/items/generate-presigned-url', {
                        fileName: newFileName,
                        fileType: image.file.type,
                        auctionId: auction.id
                    });

                    const presignedUrl = presignedResponse.data.presignedUrl;
                    await axios.put(presignedUrl, image.file, {
                        headers: {
                            'Content-Type': image.file.type,
                            'Cache-Control': 'no-cache'
                        }
                    });

                    return presignedUrl.split('?')[0];
                }

                return image.preview;
            }));

            const newItemData = {
                ...itemData,
                imageUrls: imageUrls,
                auctionId: auction.id
            };

            const response = await axios.post('/api/items/create', newItemData);
            const newItem = response.data.item;

            setItems(currentItems => [...currentItems, newItem]);
        } catch (error) {
            console.error("Can't update new item:", error);
        } finally {
            setItemData({});
            setImages([]);
            setLoadingNewItem(false);
            onClose();
        }
    };

    const onCloseConditional = () => {
        if (!loadingNewItem) {
            onClose()
        }
    }

    return (
        <Modal
            open={open}
            onClose={onCloseConditional}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='h5'>Add New Lot</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            required
                            name="title"
                            label="Title"
                            value={itemData.title}
                            onChange={handleChange}
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DragAndDropArea
                            images={images}
                            setImages={setImages}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            name="description"
                            label="Full Description"
                            value={itemData.description}
                            onChange={handleChange}
                            margin="normal"
                            multiline={true}
                            rows={4}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            name="shippingInfo"
                            label="Shipping Information"
                            value={itemData.shippingInfo}
                            onChange={handleChange}
                            margin="normal"
                            multiline={true}
                            rows={4}
                        />
                    </Grid>
                    {
                        loadingNewItem &&
                        <Grid item xs={12}>
                            <Alert severity='error'>Do not close or refresh this page until item is saved</Alert>
                        </Grid>
                    }
                    <Grid item container spacing={2}>
                        <Grid item>
                            {
                                loadingNewItem ?
                                    <Button disabled variant='contained' onClick={handleSave} >Save</Button> :
                                    <Button variant='contained' onClick={handleSave} >Save</Button>
                            }
                        </Grid>
                        <Grid item>
                            <Button variant='contained' onClick={handleCancel}>Cancel</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
};

export default AddItemModal;
