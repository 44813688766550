import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, Checkbox, TableContainer } from '@mui/material';
import { useTheme } from '@mui/styles';

const ItemsTable = ({ items, onEdit, selectedItems, setSelectedItems }) => {
    const theme = useTheme();

    const handleSelectAllClick = (event) => {
        if (event.target.checked || selectedItems.length > 0) {
            const newSelectedItems = items.length === selectedItems.length ? [] : items.map((item) => item.id);
            setSelectedItems(newSelectedItems);
            return;
        }
        setSelectedItems([]);
    };

    const handleClick = (event, itemId) => {
        if (event.target.type !== 'checkbox') {
            onEdit(itemId);
        } else {
            const selectedIndex = selectedItems.indexOf(itemId);
            let newSelectedItems = [];

            if (selectedIndex === -1) {
                newSelectedItems = newSelectedItems.concat(selectedItems, itemId);
            } else if (selectedIndex === 0) {
                newSelectedItems = newSelectedItems.concat(selectedItems.slice(1));
            } else if (selectedIndex === selectedItems.length - 1) {
                newSelectedItems = newSelectedItems.concat(selectedItems.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelectedItems = newSelectedItems.concat(
                    selectedItems.slice(0, selectedIndex),
                    selectedItems.slice(selectedIndex + 1),
                );
            }

            setSelectedItems(newSelectedItems);
        }
    };

    const isSelected = (id) => selectedItems.indexOf(id) !== -1;

    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell padding="checkbox">
                            <Checkbox
                                color="primary"
                                indeterminate={selectedItems.length > 0 && selectedItems.length < items.length}
                                checked={items.length > 0 && selectedItems.length === items.length}
                                onChange={handleSelectAllClick}
                                inputProps={{ 'aria-label': 'select all items' }}
                            />
                        </TableCell>
                        <TableCell>Photo</TableCell>
                        <TableCell>Lot Number</TableCell>
                        <TableCell>Title</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Shipping</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((item) => {
                        const isItemSelected = isSelected(item.id);

                        return (
                            <TableRow
                                key={item.id}
                                selected={isItemSelected}
                                onClick={(event) => handleClick(event, item.id)}
                            >
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        color="primary"
                                        checked={isItemSelected}
                                        inputProps={{ 'aria-label': `select item ${item.id}` }}
                                    />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {/* Thumbnail Image - Replace with actual image URL */}
                                    <img src={item.thumbnailUrl || 'default-thumbnail.jpg'} alt="Item" style={{ width: '50px', height: '50px', objectFit: 'cover' }} />
                                </TableCell>
                                <TableCell>{item.catalog_order}</TableCell>
                                <TableCell>{item.short_description}</TableCell>
                                <TableCell>{item.full_description}</TableCell>
                                <TableCell>{item.shipping_information}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ItemsTable;
