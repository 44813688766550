import axios from "axios";
import authHeader from "./auth-header";

const API_URL = 'http://localhost:8080/api/auctions/';

class AuctionService {
    getAllAuctions() {
        return axios.get(`${API_URL}/getAllAuctions`, {
            headers: authHeader(),
        })
            .then(response => response.data)
            .catch(error => {
                throw new Error(error.response.data.message || 'Error fetching auctions');
            });
    }

    getUpcomingAuctions() {
        return axios.get(`${API_URL}/getUpcomingAuctions`, {
            headers: authHeader(),
        })
            .then(response => response.data)
            .catch(error => {
                throw new Error(error.response.data.message || 'Error fetching auctions');
            });
    }

    getPreviousAuctions() {
        return axios.get(`${API_URL}/getPreviousAuctions`, {
            headers: authHeader(),
        })
            .then(response => response.data)
            .catch(error => {
                throw new Error(error.response.data.message || 'Error fetching auctions');
            });
    }

    getNextItemNumber(auctionId) {
        return axios.get(`${API_URL}/${auctionId}/nextItemNumber`, {
            headers: authHeader(),
        })
            .then(response => response.data.nextItemNumber)
            .catch(error => {
                throw new Error(error.response.data.message || 'Error fetching next available');
            })
    }
}

export default new AuctionService();