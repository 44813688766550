import React, { useContext, useEffect, useState } from "react";
import { Sidenav } from "../../components/Sidenav/Sidenav";
import { TopMenu } from "../../components/TopMenu/TopMenu";
import { UserContext } from "../../contexts/UserContext";
import { useNavigate } from "react-router-dom";

export const SellerDashboard = () => {
    const {user, setUser} = useContext(UserContext);
    const navigate = useNavigate();

    useEffect(() => {
        const localUser = JSON.parse(localStorage.getItem('user'));
        const currentStatus = user.status || localUser.status;
    
        if (currentStatus !== 'registered') {
            console.log("redirect to settings");
            navigate('/settings');
        } 
    }, [user]);
    
    return (
        <div>
            Hello Seller
        </div>
    );
};