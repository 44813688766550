import React, { useState } from 'react';
import UpcomingAuctionDropdown from '../../components/AuctionDropdown/UpcomingAuctionDropdown';
import ItemsTable from '../../components/ItemsTable/ItemsTable';
import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import AddItemModal from '../../components/Modals/AddItemModal';
import DeleteItemModal from '../../components/Modals/DeleteItemModal';
import EditItemModal from '../../components/Modals/EditItemModal';

export const UserDatabaseView = (props) => {
    const filteredUsers = props.filteredUsers;
    const handleSearchChange = props.handleSearchChange;
    const searchQuery = props.searchQuery;

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    return (
        <Grid container spacing={2} direction='column'>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label="Search Users"
                    variant="outlined"
                    value={searchQuery}
                    onChange={handleSearchChange}
                />
            </Grid>
            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredUsers.map((user) => {
                                return (
                                    <TableRow
                                        key={user.id}
                                        onClick={(event) => console.log(event, user.id)}
                                    >
                                        <TableCell>{user.id}</TableCell>
                                        <TableCell>{`${user.firstName} ${user.lastName}`}</TableCell>
                                        <TableCell>{user.email}</TableCell>
                                        <TableCell>{user.status}</TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    )
}