import React from "react";
import { SettingsView } from "./SettingsView";


export const SettingsContainer = (props) => {

    return (
        <div>
            <SettingsView
            />
        </div>
    );
};